.subscribe-bar {
  display: flex;
  color: black;

  // margin-right: 50px;
  margin-top: 15px;
  border: grey 1px solid;
  background-color: #f9f9f9;
  padding: 10px;


  input {
    width: 200px;
    font-size: 16px;
    font-family: 'Alef', 'sans-serif';
    margin-right: 10px;
    margin-left: 30px;
  }

  button {
    font-size: 16px;
    font-family: 'Alef', 'sans-serif';
  }

  .subscribe-header {
    width: 200px;
    text-align: center;
    font-size: 16px;
  }
}
