input.react-autosuggest-simple,
.MuiInputBase-input::placeholder,
.MuiPaper-root {
  font-family:'Alef','sans-serif';
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button,
input.MuiInputBase-input.MuiInput-input {
  font-family:'Alef','sans-serif';
}


