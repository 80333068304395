.map {
  height: 100%;

  display: flex;
  flex-direction: column;

  .title {
    font-size: 35px;
  }

  .sub-title {
    font-size: 20px;
    
    .sub-title-link{
      
      color: black;
      text-decoration: none;

      &:hover {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  

  iframe {
    flex: 1;
    width: 100%;
  }
}
