html {
  font-family: "Alef", "sans-serif";
}

body {
  direction: "rtl";
  margin: 0 30px;
}

.MuiTypography-root.MuiTypography-body1 {
  font-family: "Alef", "sans-serif";
}

.anyway-footer {
  display: flex;
  cursor: pointer;
}

.anyway-footer .dfc-logo {
  width: 20px;
  height: 20px;
  padding: 3px;
}

.anyway-footer .anyway-links {
  border-left: 1px solid #ddd;
  padding-left: 8px;
}

.anyway-footer a {
  margin-left: 5px;
}

.vision-zero-container {
  display: block;
  margin-top: 100px;
}

.report-select {
  height: 40px;
  .MuiInput-formControl {
    margin-top: 8px;
  }
}
