.report {
  margin: 30px 30px 50px;
  margin-top: 80px;


  .info {
    display: flex;

    .right {
      width: 350px;
      padding-left: 50px;
      display: flex;
      flex-direction: column;

      .input {
        border: none;
        outline:none;
        font-size: 35px;
      }

      .select-container {
        margin-bottom: 10px;
      }
    }

    .left {
      flex: 1;
      height: 1000px;
    }
  }

  .vision-zero-container {
    display: block;
    margin-top: 20px;
  }

  .footer {
    display: flex;
    width: 100%;

    flex-direction: column;
    align-items: center;

    div {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

}
