.report {
  min-height: 700px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 20px;
    text-decoration: underline;
  }

  iframe {
    flex: 1;
    width: 100%;
    border: none;
  }
}
