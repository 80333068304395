.stats {
  .title {
    font-size: 20px;
    text-decoration: underline;
  }

  .sub-title {
    font-size: 18px;
  }
}
